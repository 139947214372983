var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-screen" },
    [
      _vm.quote && _vm.quote.uuid
        ? _c("div", { staticClass: "container mx-auto" }, [
            _c("div", { staticClass: "w-full grid grid-cols-3" }, [
              _c("div", { staticClass: "col-span-2" }, [
                _c("h3", { staticClass: "mt-4 flex items-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "pages.commons.quotes." +
                            _vm.quoteState(_vm.quote, _vm.loginUserRole)
                        )
                      ) +
                      " "
                  ),
                  _c("img", {
                    staticClass: "w-8 h-8 mx-2 inline",
                    attrs: {
                      src: require("@/assets/image/negotiating_icon.svg"),
                    },
                  }),
                ]),
              ]),
              _vm.quote && _vm.quote.created_at
                ? _c("div", { staticClass: "text-right col-span-1 mt-3" }, [
                    _c("p", [
                      _vm._v(
                        "Requested at: " +
                          _vm._s(_vm._f("date")(_vm.quote.created_at))
                      ),
                    ]),
                    _vm.quote.status > 2
                      ? _c("p", [
                          _vm._v(
                            "Delivery by: " +
                              _vm._s(_vm._f("date")(_vm.quote.delivery_at))
                          ),
                        ])
                      : _c("p", [
                          _vm._v(
                            "Requested by: " +
                              _vm._s(_vm._f("date")(_vm.quote.estimate_at))
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "w-full text-sm" }, [
              _c(
                "p",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.commons.quotes.id")) + ": "
                  ),
                  _c("FlCopyableText", [_vm._v(_vm._s(_vm.quote.uuid))]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "grid grid-cols-2 gap-x-6" }, [
              _c("div", { staticClass: "col-span-1 pt-4" }, [
                _c("span", { staticClass: "pr-2" }, [
                  _vm._v(
                    _vm._s(_vm.quote.consumer.company_name) +
                      "(" +
                      _vm._s(_vm.quote.consumer.name) +
                      ")"
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "px-3 py-1 bg-base-yellow font-bold",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.impersonateConsumer(_vm.quote.consumer)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("pages.administrators.commons.impersonate")
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "col-span-1" }, [
                _c(
                  "h4",
                  { staticClass: "text-right pt-4" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("pages.commons.quotes.transaction_price_admin")
                        ) +
                        _vm._s(
                          _vm._f("price")(
                            _vm.showInYen(
                              _vm.quote,
                              _vm.quotesTotalMax(_vm.quote)
                            ) + _vm.adjustmentTotal(_vm.quote, _vm.quoteMovies),
                            "JPY"
                          )
                        ) +
                        " "
                    ),
                    _vm.quote.price_minimum_guarantee > 0
                      ? [
                          _vm._v(
                            " (" +
                              _vm._s(_vm.$t("pages.commons.quotes.mg")) +
                              _vm._s(
                                _vm._f("price")(
                                  _vm.showInYen(
                                    _vm.quote,
                                    _vm.quotesTotalMG(_vm.quote)
                                  ),
                                  "JPY"
                                )
                              ) +
                              ") "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
            _vm.providers
              ? _c(
                  "div",
                  _vm._l(_vm.currentQuotes, function (qm, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "mb-6 flex flex-row" },
                      [
                        _vm.isSplitMode || _vm.isMoveMode
                          ? _c(
                              "div",
                              { staticClass: "inline-block flex-none my-2" },
                              [
                                _c("FlCheckbox", {
                                  attrs: { trueValue: { uuid: qm.uuid } },
                                  model: {
                                    value: _vm.modifyTargets,
                                    callback: function ($$v) {
                                      _vm.modifyTargets = $$v
                                    },
                                    expression: "modifyTargets",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "inline-block flex-auto" },
                          [
                            _c(
                              "QuoteMovieViewItem",
                              {
                                attrs: {
                                  title: _vm.getQMTitleWithState(
                                    qm,
                                    _vm.loginUserRole
                                  ),
                                  isOK: qm.approved_by,
                                  hidesTrash: true,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.providers && _vm.providers.length,
                                        expression:
                                          "providers && providers.length",
                                      },
                                    ],
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 gap-y-2 pb-2 border-dashed border-b border-brown-grey",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-span-10 my-auto" },
                                      [
                                        _c(
                                          "span",
                                          [
                                            _vm._v(" Fee for "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "underline",
                                                attrs: {
                                                  to:
                                                    "/admin/chs/" +
                                                    _vm.quote.movies[key].movie
                                                      .provided_by.uuid,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.providerDisplayName(
                                                        _vm.quote.movies[key]
                                                          .movie
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "px-3 py-1 bg-base-yellow font-bold ml-2",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.impersonateProvider(
                                                      _vm.providers[key].uuid,
                                                      qm
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.administrators.commons.impersonate"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                qm.status >= 5
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.administrators.commons.correct_estimate"
                                                            )
                                                          ) +
                                                          ")"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            qm.status >= 5
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "px-3 py-1 bg-base-yellow font-bold ml-2",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.impersonateProvider(
                                                          _vm.providers[key]
                                                            .uuid,
                                                          qm,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "pages.administrators.commons.impersonate"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm.quote.pricing_type === 1
                                      ? [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table-auto col-span-12 min-w-full divide-y divide-gray-200 border-solid border rounded",
                                            },
                                            [
                                              _c(
                                                "thead",
                                                { staticClass: "bg-gray-50" },
                                                [
                                                  _c("tr", [
                                                    _c("th", {
                                                      staticClass:
                                                        "px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider",
                                                      attrs: { scope: "col" },
                                                    }),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider",
                                                        attrs: { scope: "col" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " VOD提案 / 総額 "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider",
                                                        class:
                                                          _vm.quoteMovies[key]
                                                            .quote_provider
                                                            .status > 2
                                                            ? "block"
                                                            : "hidden",
                                                        attrs: { scope: "col" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " CH見積 / 総額 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "tbody",
                                                {
                                                  staticClass:
                                                    "bg-white divide-y divide-gray-200",
                                                },
                                                [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                        },
                                                        [_vm._v("VOD支払")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "bg-gray-300 text-gray-500"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額(固定費):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.consumerFixedPriceYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    true
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c("span", [
                                                              _vm._v(
                                                                " (通貨:" +
                                                                  _vm._s(
                                                                    _vm.quote
                                                                      .currency
                                                                  ) +
                                                                  " 金額:" +
                                                                  _vm._s(
                                                                    _vm.formatCurrency(
                                                                      _vm.quote
                                                                        .flat_price,
                                                                      _vm.quote
                                                                        .currency
                                                                    )
                                                                  ) +
                                                                  ") "
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "block"
                                                              : "hidden",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額(固定費):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.consumerFixedPriceYenString(
                                                                  _vm.quote,
                                                                  qm
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v("FI手数料"),
                                                          ]),
                                                          _c("input", {
                                                            staticClass:
                                                              "input_text disabled:bg-very-light-grey disabled:bg-gray-700 w-16",
                                                            attrs: {
                                                              disabled:
                                                                qm.status > 1,
                                                              type: "number",
                                                            },
                                                            domProps: {
                                                              value:
                                                                qm.fee_rate ||
                                                                _vm.providers[
                                                                  key
                                                                ].fee_rate,
                                                            },
                                                            on: {
                                                              input: function (
                                                                event
                                                              ) {
                                                                return (_vm.quoteMovies[
                                                                  key
                                                                ].fee_rate = parseInt(
                                                                  event.target
                                                                    .value
                                                                ))
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                            },
                                                            [_vm._v("%")]
                                                          ),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4 rounded",
                                                              attrs: {
                                                                disabled:
                                                                  qm.status > 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.approveFee(
                                                                      qm.uuid
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              qm.approved_by &&
                                                              qm.fee_rate
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "pages.commons.quotes.update"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "pages.providers.index.approve"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "bg-gray-300 text-gray-500"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額 × FI手数料(％) - 調整金 = FI手数料(円):"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.quoteMovies[key]
                                                            .quote_provider
                                                            .status > 2
                                                            ? _c("div", [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.filminationFixedPriceString(
                                                                          _vm.quote,
                                                                          qm,
                                                                          true
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ])
                                                            : _c("div", [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.filminationFixedPriceStringNoAdjust(
                                                                          _vm.quote,
                                                                          qm
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "mx-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "-"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model.number",
                                                                          value:
                                                                            qm.adjustment_yen,
                                                                          expression:
                                                                            "qm.adjustment_yen",
                                                                          modifiers:
                                                                            {
                                                                              number: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "input_text w-24",
                                                                    attrs: {
                                                                      type: "number",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        qm.adjustment_yen,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          ) {
                                                                            return
                                                                          }
                                                                          _vm.$set(
                                                                            qm,
                                                                            "adjustment_yen",
                                                                            _vm._n(
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          )
                                                                        },
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$forceUpdate()
                                                                      },
                                                                    },
                                                                  }),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "mx-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "="
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.filminationFixedPriceString(
                                                                          _vm.quote,
                                                                          qm
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm
                                                                            .quoteMovies[
                                                                            key
                                                                          ]
                                                                            .adjustment_yen ===
                                                                            null ||
                                                                          qm.approved_by ===
                                                                            null,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.applyFlFee(
                                                                              qm.uuid
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " 保存 "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                              ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "block"
                                                              : "hidden",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額 × FI手数料(％) - 調整金 = FI手数料(円):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.filminationFixedPriceStringNoAdjust(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [_vm._v("-")]
                                                            ),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model.number",
                                                                  value:
                                                                    qm.adjustment_yen,
                                                                  expression:
                                                                    "qm.adjustment_yen",
                                                                  modifiers: {
                                                                    number: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "input_text w-24",
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  qm.adjustment_yen,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      qm,
                                                                      "adjustment_yen",
                                                                      _vm._n(
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    )
                                                                  },
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [_vm._v("=")]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.filminationFixedPriceString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm
                                                                      .quoteMovies[
                                                                      key
                                                                    ]
                                                                      .adjustment_yen ===
                                                                      null ||
                                                                    qm.approved_by ===
                                                                      null,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.applyFlFee(
                                                                        qm.uuid
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" 保存 ")]
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                        },
                                                        [_vm._v("CH売上")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "bg-gray-300 text-gray-500"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額 - FI手数料(円) = CH売上(固定費):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.providerFixedPriceString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    true
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "block"
                                                              : "hidden",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額 - FI手数料(円) = CH売上(固定費):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.consumerFixedPriceYenString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [_vm._v("-")]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.filminationFixedPriceString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [_vm._v("=")]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.providerFixedPriceString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "px-6 py-4 whitespace-nowrap",
                                                      },
                                                      [_vm._v("Excel DL")]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "px-6 py-4 whitespace-nowrap",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            qm &&
                                                            (qm.status < 3 ||
                                                              qm.status === 8)
                                                              ? _c(
                                                                  "LinkButton",
                                                                  {
                                                                    attrs: {
                                                                      classList:
                                                                        "px-2 py-3 bg-light-yellow text-xs",
                                                                      disabled:
                                                                        qm.status <
                                                                        2,
                                                                    },
                                                                    on: {
                                                                      clickButton:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.downloadFileCh(
                                                                            _vm.quoteMovies.filter(
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return (
                                                                                  item
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.quote,
                                                                            _vm.providers.find(
                                                                              function (
                                                                                provider
                                                                              ) {
                                                                                return (
                                                                                  provider.uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.movies,
                                                                            _vm.translatedMessage,
                                                                            _vm.translatedContractMessage,
                                                                            false
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " file for CH (temporary) "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "LinkButton",
                                                                  {
                                                                    attrs: {
                                                                      classList:
                                                                        "px-2 py-3 bg-light-yellow text-xs",
                                                                    },
                                                                    on: {
                                                                      clickButton:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.downloadFileCh(
                                                                            _vm.quoteMovies.filter(
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return (
                                                                                  item
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.quote,
                                                                            _vm.providers.find(
                                                                              function (
                                                                                provider
                                                                              ) {
                                                                                return (
                                                                                  provider.uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.movies,
                                                                            _vm.translatedMessage,
                                                                            _vm.translatedContractMessage,
                                                                            true
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " file for CH (final) "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "px-6 py-4 whitespace-nowrap",
                                                        class:
                                                          _vm.quoteMovies[key]
                                                            .quote_provider
                                                            .status > 2
                                                            ? "block"
                                                            : "hidden",
                                                      },
                                                      [
                                                        _c(
                                                          "LinkButton",
                                                          {
                                                            attrs: {
                                                              classList:
                                                                "px-2 py-3 bg-light-yellow text-xs",
                                                              disabled:
                                                                _vm.quote
                                                                  .status < 3,
                                                            },
                                                            on: {
                                                              clickButton:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.downloadFileVod(
                                                                    _vm.quoteMovies,
                                                                    _vm.quote,
                                                                    _vm.movies
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " file for VOD (final) "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.quote.pricing_type !== 1
                                      ? [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table-auto col-span-12 min-w-full divide-y divide-gray-200 border-solid border rounded",
                                            },
                                            [
                                              _c(
                                                "thead",
                                                { staticClass: "bg-gray-50" },
                                                [
                                                  _c("tr", [
                                                    _c("th", {
                                                      staticClass:
                                                        "px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider",
                                                      attrs: { scope: "col" },
                                                    }),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider",
                                                        attrs: { scope: "col" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " VOD提案 / 総額 "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider",
                                                        class:
                                                          _vm.quoteMovies[key]
                                                            .quote_provider
                                                            .status > 2
                                                            ? "block"
                                                            : "hidden",
                                                        attrs: { scope: "col" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " CH見積 / 総額 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "tbody",
                                                {
                                                  staticClass:
                                                    "bg-white divide-y divide-gray-200",
                                                },
                                                [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                        },
                                                        [_vm._v("VOD支払")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "bg-gray-300 text-gray-500"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD想定売上 MIN 〜 MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.consumerContractPriceMinYenString(
                                                                      _vm.quote,
                                                                      qm,
                                                                      true
                                                                    )
                                                                  ) +
                                                                  " 〜 " +
                                                                  _vm._s(
                                                                    _vm.consumerContractPriceMaxYenString(
                                                                      _vm.quote,
                                                                      qm,
                                                                      true
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                            _c("span", [
                                                              _vm._v(
                                                                " (通貨:" +
                                                                  _vm._s(
                                                                    _vm.quote
                                                                      .currency
                                                                  ) +
                                                                  " 金額:" +
                                                                  _vm._s(
                                                                    _vm.formatCurrency(
                                                                      _vm.quote
                                                                        .revenue_min,
                                                                      _vm.quote
                                                                        .currency
                                                                    )
                                                                  ) +
                                                                  " 〜 " +
                                                                  _vm._s(
                                                                    _vm.formatCurrency(
                                                                      _vm.quote
                                                                        .revenue_max,
                                                                      _vm.quote
                                                                        .currency
                                                                    )
                                                                  ) +
                                                                  " 期間:" +
                                                                  _vm._s(
                                                                    _vm.contractMonths(
                                                                      _vm.quote
                                                                    )
                                                                  ) +
                                                                  "ヶ月) "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Revenue share(RS) %:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.quote
                                                                  .revenue_percent
                                                              )
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                              },
                                                              [_vm._v("%")]
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD想定支払額(RS) MIN 〜 MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.consumerContractPriceMinYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    false
                                                                  )
                                                                ) +
                                                                " 〜 " +
                                                                _vm._s(
                                                                  _vm.consumerContractPriceMaxYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    false
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額(MG):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.consumerMGPriceYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    true
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "block"
                                                              : "hidden",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD想定売上MIN 〜 MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.consumerContractPriceMinYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    true
                                                                  )
                                                                ) +
                                                                " 〜 " +
                                                                _vm._s(
                                                                  _vm.consumerContractPriceMaxYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    true
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Revenue share(RS) %:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.quote
                                                                  .revenue_percent
                                                              )
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                              },
                                                              [_vm._v("%")]
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD想定支払額(RS) MIN 〜 MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.consumerContractPriceMinYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    false
                                                                  )
                                                                ) +
                                                                " 〜 " +
                                                                _vm._s(
                                                                  _vm.consumerContractPriceMaxYenString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    false
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "VOD支払額(MG):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.consumerMGPriceYenString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v("FI手数料"),
                                                          ]),
                                                          _c("input", {
                                                            staticClass:
                                                              "input_text disabled:bg-very-light-grey disabled:bg-gray-700 w-16",
                                                            attrs: {
                                                              disabled:
                                                                qm.status > 1,
                                                              type: "number",
                                                            },
                                                            domProps: {
                                                              value:
                                                                qm.fee_rate ||
                                                                _vm.providers[
                                                                  key
                                                                ].fee_rate,
                                                            },
                                                            on: {
                                                              input: function (
                                                                event
                                                              ) {
                                                                return (_vm.quoteMovies[
                                                                  key
                                                                ].fee_rate = parseInt(
                                                                  event.target
                                                                    .value
                                                                ))
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                            },
                                                            [_vm._v("%")]
                                                          ),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4 rounded",
                                                              attrs: {
                                                                disabled:
                                                                  qm.status > 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.approveFee(
                                                                      qm.uuid
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              qm.approved_by &&
                                                              qm.fee_rate
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "pages.commons.quotes.update"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "pages.providers.index.approve"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "bg-gray-300 text-gray-500"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "FI想定手数料(円)(RS) MIN ~ MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.filminationPriceMinString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " 〜 " +
                                                                _vm._s(
                                                                  _vm.filminationPriceMaxString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "FI手数料(MG) - 調整金:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.quoteMovies[key]
                                                            .quote_provider
                                                            .status > 2
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.filminationMGPriceString(
                                                                        _vm.quote,
                                                                        qm,
                                                                        true
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.filminationMGPriceStringNoAdjust(
                                                                        _vm.quote,
                                                                        qm,
                                                                        true
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                  },
                                                                  [_vm._v("-")]
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model.number",
                                                                      value:
                                                                        _vm
                                                                          .quoteMovies[
                                                                          key
                                                                        ]
                                                                          .price_minimum_guarantee_fl_yen,
                                                                      expression:
                                                                        "quoteMovies[key].price_minimum_guarantee_fl_yen",
                                                                      modifiers:
                                                                        {
                                                                          number: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "input_text w-24",
                                                                  attrs: {
                                                                    type: "number",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .quoteMovies[
                                                                        key
                                                                      ]
                                                                        .price_minimum_guarantee_fl_yen,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          _vm
                                                                            .quoteMovies[
                                                                            key
                                                                          ],
                                                                          "price_minimum_guarantee_fl_yen",
                                                                          _vm._n(
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        )
                                                                      },
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$forceUpdate()
                                                                    },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                  },
                                                                  [_vm._v("=")]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.filminationMGPriceString(
                                                                        _vm.quote,
                                                                        qm,
                                                                        true
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm
                                                                          .quoteMovies[
                                                                          key
                                                                        ]
                                                                          .price_minimum_guarantee_fl_yen ===
                                                                          null ||
                                                                        qm.approved_by ===
                                                                          null,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.applyFlMg(
                                                                            qm.uuid
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " 保存 "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "block"
                                                              : "hidden",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "FI想定手数料(円)(RS) MIN ~ MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.filminationPriceMinString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " 〜 " +
                                                                _vm._s(
                                                                  _vm.filminationPriceMaxString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "FI手数料(MG) - 調整金:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.filminationMGPriceStringNoAdjust(
                                                                  _vm.quote,
                                                                  qm
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                            },
                                                            [_vm._v("-")]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model.number",
                                                                value:
                                                                  _vm
                                                                    .quoteMovies[
                                                                    key
                                                                  ]
                                                                    .price_minimum_guarantee_fl_yen,
                                                                expression:
                                                                  "quoteMovies[key].price_minimum_guarantee_fl_yen",
                                                                modifiers: {
                                                                  number: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "input_text w-24",
                                                            attrs: {
                                                              type: "number",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.quoteMovies[
                                                                  key
                                                                ]
                                                                  .price_minimum_guarantee_fl_yen,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm
                                                                    .quoteMovies[
                                                                    key
                                                                  ],
                                                                  "price_minimum_guarantee_fl_yen",
                                                                  _vm._n(
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                )
                                                              },
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.$forceUpdate()
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                            },
                                                            [_vm._v("=")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.filminationMGPriceString(
                                                                  _vm.quote,
                                                                  qm
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4",
                                                              attrs: {
                                                                disabled:
                                                                  _vm
                                                                    .quoteMovies[
                                                                    key
                                                                  ]
                                                                    .price_minimum_guarantee_fl_yen ===
                                                                    null ||
                                                                  qm.approved_by ===
                                                                    null,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.applyFlMg(
                                                                      qm.uuid
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 保存 ")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                        },
                                                        [_vm._v("CH売上")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "bg-gray-300 text-gray-500"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "CH想定売上(RS) MIN 〜 MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.providerPlannedPriceMinString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    true
                                                                  )
                                                                ) +
                                                                " 〜 " +
                                                                _vm._s(
                                                                  _vm.providerPlannedPriceMaxString(
                                                                    _vm.quote,
                                                                    qm,
                                                                    true
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "CH売上(MG):"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.quoteMovies[key]
                                                            .quote_provider
                                                            .status > 2
                                                            ? _c("div", [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.providerMGPriceString(
                                                                          _vm.quote,
                                                                          qm,
                                                                          true
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ])
                                                            : _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.providerMGPriceStringNoAdjust(
                                                                        _vm.quote,
                                                                        qm,
                                                                        true
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                  },
                                                                  [_vm._v("+")]
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model.number",
                                                                      value:
                                                                        _vm
                                                                          .quoteMovies[
                                                                          key
                                                                        ]
                                                                          .price_minimum_guarantee_fl_yen,
                                                                      expression:
                                                                        "quoteMovies[key].price_minimum_guarantee_fl_yen",
                                                                      modifiers:
                                                                        {
                                                                          number: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "input_text w-24",
                                                                  attrs: {
                                                                    type: "number",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .quoteMovies[
                                                                        key
                                                                      ]
                                                                        .price_minimum_guarantee_fl_yen,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          _vm
                                                                            .quoteMovies[
                                                                            key
                                                                          ],
                                                                          "price_minimum_guarantee_fl_yen",
                                                                          _vm._n(
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        )
                                                                      },
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$forceUpdate()
                                                                    },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                  },
                                                                  [_vm._v("=")]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.providerMGPriceString(
                                                                        _vm.quote,
                                                                        qm,
                                                                        true
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm
                                                                          .quoteMovies[
                                                                          key
                                                                        ]
                                                                          .price_minimum_guarantee_fl_yen ===
                                                                          null ||
                                                                        qm.approved_by ===
                                                                          null,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.applyFlMg(
                                                                            qm.uuid
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " 保存 "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "px-6 py-4 whitespace-nowrap",
                                                          class:
                                                            _vm.quoteMovies[key]
                                                              .quote_provider
                                                              .status > 2
                                                              ? "block"
                                                              : "hidden",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "CH想定売上(RS) MIN 〜 MAX:"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.providerPlannedPriceMinString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " 〜 " +
                                                                _vm._s(
                                                                  _vm.providerPlannedPriceMaxString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "CH売上(MG):"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.providerMGPriceStringNoAdjust(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [_vm._v("+")]
                                                            ),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model.number",
                                                                  value:
                                                                    _vm
                                                                      .quoteMovies[
                                                                      key
                                                                    ]
                                                                      .price_minimum_guarantee_fl_yen,
                                                                  expression:
                                                                    "quoteMovies[key].price_minimum_guarantee_fl_yen",
                                                                  modifiers: {
                                                                    number: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "input_text w-24",
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .quoteMovies[
                                                                    key
                                                                  ]
                                                                    .price_minimum_guarantee_fl_yen,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm
                                                                        .quoteMovies[
                                                                        key
                                                                      ],
                                                                      "price_minimum_guarantee_fl_yen",
                                                                      _vm._n(
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    )
                                                                  },
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [_vm._v("=")]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.providerMGPriceString(
                                                                    _vm.quote,
                                                                    qm
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "px-6 py-2 bg-base-yellow font-bold text-right disabled:bg-very-light-grey ml-4",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm
                                                                      .quoteMovies[
                                                                      key
                                                                    ]
                                                                      .price_minimum_guarantee_fl_yen ===
                                                                      null ||
                                                                    qm.approved_by ===
                                                                      null,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.applyFlMg(
                                                                        qm.uuid
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" 保存 ")]
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "px-6 py-4 whitespace-nowrap",
                                                      },
                                                      [_vm._v("Excel DL")]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "px-6 py-4 whitespace-nowrap",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            qm &&
                                                            (qm.status < 3 ||
                                                              qm.status === 8)
                                                              ? _c(
                                                                  "LinkButton",
                                                                  {
                                                                    attrs: {
                                                                      classList:
                                                                        "px-2 py-3 bg-light-yellow text-xs",
                                                                      disabled:
                                                                        qm.status <
                                                                          2 &&
                                                                        !_vm.canDownload,
                                                                    },
                                                                    on: {
                                                                      clickButton:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.downloadFileCh(
                                                                            _vm.quoteMovies.filter(
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return (
                                                                                  item
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.quote,
                                                                            _vm.providers.find(
                                                                              function (
                                                                                provider
                                                                              ) {
                                                                                return (
                                                                                  provider.uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.movies,
                                                                            _vm.translatedMessage,
                                                                            _vm.translatedContractMessage,
                                                                            false
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " file for CH (temporary) "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "LinkButton",
                                                                  {
                                                                    attrs: {
                                                                      classList:
                                                                        "px-2 py-3 bg-light-yellow text-xs",
                                                                      disabled:
                                                                        !_vm.canDownload,
                                                                    },
                                                                    on: {
                                                                      clickButton:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.downloadFileCh(
                                                                            _vm.quoteMovies.filter(
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return (
                                                                                  item
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.quote,
                                                                            _vm.providers.find(
                                                                              function (
                                                                                provider
                                                                              ) {
                                                                                return (
                                                                                  provider.uuid ===
                                                                                  qm
                                                                                    .movie
                                                                                    .provided_by
                                                                                    .uuid
                                                                                )
                                                                              }
                                                                            ),
                                                                            _vm.movies,
                                                                            _vm.translatedMessage,
                                                                            _vm.translatedContractMessage,
                                                                            true
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " file for CH (final) "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "px-6 py-4 whitespace-nowrap",
                                                        class:
                                                          _vm.quoteMovies[key]
                                                            .quote_provider
                                                            .status > 2
                                                            ? "block"
                                                            : "hidden",
                                                      },
                                                      [
                                                        _c(
                                                          "LinkButton",
                                                          {
                                                            attrs: {
                                                              classList:
                                                                "px-2 py-3 bg-light-yellow text-xs",
                                                              disabled:
                                                                _vm.quote
                                                                  .status < 3 &&
                                                                !_vm.canDownload,
                                                            },
                                                            on: {
                                                              clickButton:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.downloadFileVod(
                                                                    _vm.quoteMovies,
                                                                    _vm.quote,
                                                                    _vm.movies
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " file for VOD (final) "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 gap-y-2 py-2 border-dashed border-b border-brown-grey",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-span-3 flex flex-col justify-center",
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "配信開始予定年月日：" +
                                              _vm._s(
                                                _vm.getDateString(
                                                  qm.license_start_at,
                                                  false
                                                )
                                              )
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "配信終了予定年月日：" +
                                              _vm._s(
                                                _vm.getDateString(
                                                  qm.license_end_at,
                                                  false
                                                )
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-span-3 flex flex-col justify-center",
                                      },
                                      [
                                        _c("FlDate2", {
                                          attrs: {
                                            label: "実配信開始日",
                                            fromDate: _vm.ignoreContractDelay[
                                              key
                                            ]
                                              ? null
                                              : qm.license_start_at,
                                            toDate: _vm.ignoreContractDelay[key]
                                              ? null
                                              : _vm.calcLatestDistributionStart(
                                                  qm.license_start_at,
                                                  qm.latest_distribution_start_duration
                                                ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.licenseStartedAtChanged(
                                                $event,
                                                key
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.quoteMovies[key]
                                                .license_started_at,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.quoteMovies[key],
                                                "license_started_at",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "quoteMovies[key].license_started_at",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-span-3 flex flex-col justify-center",
                                      },
                                      [
                                        _c("FlDate2", {
                                          attrs: {
                                            disabled: !_vm.modifyDates[key],
                                            label: "実配信終了日",
                                          },
                                          model: {
                                            value:
                                              _vm.quoteMovies[key]
                                                .license_will_end_at,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.quoteMovies[key],
                                                "license_will_end_at",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "quoteMovies[key].license_will_end_at",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-span-2 flex flex-col justify-center",
                                      },
                                      [
                                        _c(
                                          "FlCheckbox",
                                          {
                                            model: {
                                              value: _vm.modifyDates[key],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.modifyDates,
                                                  key,
                                                  $$v
                                                )
                                              },
                                              expression: "modifyDates[key]",
                                            },
                                          },
                                          [_vm._v("契約期間変更")]
                                        ),
                                        _c(
                                          "FlCheckbox",
                                          {
                                            model: {
                                              value:
                                                _vm.ignoreContractDelay[key],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ignoreContractDelay,
                                                  key,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ignoreContractDelay[key]",
                                            },
                                          },
                                          [_vm._v("猶予期間を無視")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-span-1 flex flex-col justify-center",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "px-2 w-full text-center py-2 bg-base-yellow font-bold disabled:bg-very-light-grey",
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateDistributionDate(
                                                  _vm.quoteMovies[key]
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 更新 ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 gap-y-2 py-2 border-dashed border-b border-brown-grey",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-start-2 col-span-10",
                                      },
                                      [
                                        _c("FlTextArea", {
                                          attrs: { label: "運用メモ" },
                                          model: {
                                            value:
                                              _vm.quoteMovies[key]
                                                .management_note,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.quoteMovies[key],
                                                "management_note",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "quoteMovies[key].management_note",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-start-5 col-span-4" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "px-10 w-full text-center py-2 bg-base-yellow font-bold disabled:bg-very-light-grey",
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateNote(
                                                  _vm.quoteMovies[key]
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" メモを更新 ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                qm && qm.status == 3
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 py-2 border-dashed border-b border-brown-grey",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-start-2 col-span-4",
                                          },
                                          [
                                            _c(
                                              "LinkButton",
                                              {
                                                attrs: {
                                                  classList:
                                                    "px-2 py-3 text-lg w-full bg-light-pink hover:bg-light-yellow",
                                                  prevButton: false,
                                                },
                                                on: {
                                                  clickButton: function (
                                                    $event
                                                  ) {
                                                    return _vm.cancelQuote(qm)
                                                  },
                                                },
                                              },
                                              [_vm._v(" 差し戻す ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("div", { staticClass: "w-full grid grid-cols-12 gap-6 mb-10" }, [
              _c(
                "button",
                {
                  staticClass:
                    "col-span-2 text-center bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed px-2 py-4",
                  attrs: { disabled: _vm.isMoveMode },
                  on: {
                    click: function ($event) {
                      _vm.isSplitMode = !_vm.isSplitMode
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isSplitMode ? "キャンセル" : "分割モード") +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "col-start-5 col-span-4" },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: {
                        disabled:
                          !(_vm.allAccepted && _vm.quote.status == 1) ||
                          _vm.isSplitMode ||
                          _vm.isMoveMode,
                        classList: "px-2 py-4 text-lg w-full",
                        prevButton: false,
                      },
                      on: {
                        clickButton: function ($event) {
                          return _vm.$modal.show("checkApproveQuote")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "CHへ提出" +
                          _vm._s(
                            _vm.quote && _vm.quote.status == 1 ? "する" : "済"
                          )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.isSplitMode
                ? _c(
                    "button",
                    {
                      staticClass:
                        "col-start-11 col-span-2 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed px-2 py-4",
                      attrs: { disabled: _vm.modifyTargets.length === 0 },
                      on: {
                        click: function ($event) {
                          return _vm.splitQuote()
                        },
                      },
                    },
                    [_vm._v(" 分割実行 ")]
                  )
                : _vm._e(),
              _vm.isMoveMode
                ? _c(
                    "button",
                    {
                      staticClass:
                        "col-start-11 col-span-2 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed px-2 py-4",
                      attrs: {
                        disabled:
                          _vm.modifyTargets.length === 0 ||
                          _vm.selectedDestination === null,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.moveQuote()
                        },
                      },
                    },
                    [_vm._v(" 移動実行 ")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.quote
        ? _c("NoticeModal", {
            attrs: {
              modalName: "checkApproveQuote",
              title: "見積もりの提出",
              contents:
                _vm.allProviderDisplayNames() +
                "に見積もりがメール送信されます。本当に送信しますか？",
              buttons: ["キャンセル", "OK"],
            },
            on: {
              click: function ($event) {
                return _vm.approveQuote()
              },
            },
          })
        : _vm._e(),
      _c("NoticeModal", {
        attrs: {
          modalName: "successFailModal",
          contents: _vm.successFailMessage,
          buttons: ["OK"],
        },
      }),
      _c("NoticeLinkModal", { attrs: { modalName: "splitSuccessModal" } }, [
        _c("div", { staticClass: "w-full" }, [
          _c(
            "button",
            {
              staticClass:
                "px-2 py-2 w-full h-full relative text-center bg-base-yellow font-bold mb-4",
              on: { click: _vm.reloadCurrentQuote },
            },
            [_c("span", [_vm._v("現在のquoteにとどまる")])]
          ),
          _c(
            "button",
            {
              staticClass:
                "px-2 py-2 w-full h-full relative text-center bg-base-yellow font-bold mb-4",
              on: { click: _vm.moveToNewQuote },
            },
            [_c("span", [_vm._v("コピーされたquoteに移動する")])]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }