var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-6" }, [
    _c(
      "div",
      {
        staticClass: "p-2 border boder-b-0 border-very-light-grey",
        on: { click: _vm.toggle },
      },
      [
        _c(
          "div",
          { staticClass: "w-full cursor-pointer grid grid-cols-12 gap-2" },
          [
            _c("div", { staticClass: "col-span-7 flex items-center" }, [
              _c("div", { staticClass: "inline-block" }, [
                !_vm.hiddenIcon && !_vm.isOK
                  ? _c("img", {
                      staticClass: "w-6 h-6",
                      attrs: { src: require("@/assets/image/alert_icon.svg") },
                    })
                  : !_vm.hiddenIcon
                  ? _c("img", {
                      staticClass: "w-6 h-6",
                      attrs: { src: require("@/assets/image/ok_icon.svg") },
                    })
                  : _vm._e(),
              ]),
              _c("span", { staticClass: "text-lg ml-2" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]),
            _c("div", { staticClass: "col-span-4 flex items-center" }, [
              _c("span", { staticClass: "text-lg" }, [
                _vm._v(_vm._s(_vm.subtitle)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-span-1 flex items-center justify-end" },
              [
                !_vm.hidesTrash
                  ? _c(
                      "button",
                      {
                        staticClass: "inline-block",
                        attrs: { disabled: _vm.disableTrash },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.removeItem.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "w-6 h-6",
                          attrs: {
                            src: require("@/assets/image/trash_icon_grey.svg"),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "ml-2 inline-block" }, [
                  _vm.isOpen && !_vm.isOK && _vm.disabledIcon
                    ? _c("img", {
                        staticClass: "w-6 h-6",
                        attrs: {
                          src: require("@/assets/image/close_field_icon_disabled.svg"),
                        },
                      })
                    : _vm.isOpen
                    ? _c("img", {
                        staticClass: "w-6 h-6",
                        attrs: {
                          src: require("@/assets/image/close_field_icon.svg"),
                        },
                      })
                    : _c("img", {
                        staticClass: "w-6 h-6",
                        attrs: {
                          src: require("@/assets/image/open_field_icon.svg"),
                        },
                      }),
                ]),
              ]
            ),
          ]
        ),
        _vm.bottomTitle.length
          ? _c("div", { staticClass: "w-full cursor-pointer" }, [
              _c("p", { staticClass: "text-lg text-right mt-2 px-2 mr-14" }, [
                _vm._v(" " + _vm._s(_vm.bottomTitle) + " "),
              ]),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        staticClass: "border border-t-0 border-very-light-grey p-2 pb-0",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }