var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LinkButton",
    {
      attrs: { classList: "px-2 py-3 bg-light-yellow", disabled: _vm.disabled },
      on: { clickButton: _vm.download },
    },
    [
      _vm._t("default", function () {
        return [_vm._v("button")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }